<template>
  <section class="lh-WrapperSingle">
    <div class="lh-Wrapper">
      <div class="lh-Wrapper-box lh-Wrapper-box--small">
        <div class="lh-Wrapper-boxContent">
          <ul class="lh-Breadcrumb">
            <li class="lh-Breadcrumb-item">
              <router-link class="lh-Breadcrumb-link" to="/"
                >Accueil</router-link
              >
            </li>
            <li class="lh-Breadcrumb-item">
              <a class="lh-Breadcrumb-link" href="/about/">À propos</a>
            </li>
          </ul>
          <h1 class="lh-Accordion-title js-lh-About-inputAnimate">
            {{ title }}
          </h1>
          <div class="js-lh-Accordion">
            <ul class="lh-Accordion-list">
              <li
                class="lh-Accordion-listItem js-lh-About-inputAnimate"
                v-for="item in items"
                :key="item.svg"
              >
                <button
                  class="lh-Accordion-button"
                  :class="item.isActive ? 'lh-Accordion-button--active' : ''"
                  @click="expand(item)"
                >
                  {{ item.title }}
                </button>
                <transition
                  name="lh-Accordion-item"
                  @enter="startTransition"
                  @after-enter="endTransition"
                  @before-leave="startTransition"
                  @after-leave="endTransition"
                >
                  <div class="lh-Accordion-content" v-if="item.isActive">
                    <div class="lh-Accordion-inner" v-html="item.text"></div>
                  </div>
                </transition>
              </li>
            </ul>
          </div>
          <div class="lh-LinkToContainer">
            <router-link
              class="lh-Button js-lh-About-inputAnimate"
              to="/boutique"
              >Voir la boutique</router-link
            >
          </div>
        </div>
      </div>
      <div class="lh-Wrapper-box lh-Wrapper-box--big lh-Wrapper-box--dark">
        <div class="lh-Wrapper-boxContent">
          <div class="lh-Accordion-bg" name="about"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { gsap, Power3 } from "gsap";

export default {
  name: "about",
  data: () => {
    return {
      title: "À propos de LHight",
      items: [
        {
          title: "D’où vient cette lumière ?",
          text: `
            <p>La lampe LHight représente le signe LH que nous connaissons bien au Havre : la position des doigts forme les initiales de "Le Havre". Un clin d’œil à "la Californie à deux heures de Paris". Les Havrais ont emprunté le concept aux habitants de Los Angeles : signer leur ville avec leurs mains (LA).
              <br/>
              <br/>
              L’abat-jour représente le côté culturel et l’affection des Havrais pour les pots de yaourts.
            </p>
          `,
          isActive: true,
        },
        {
          title: "Qui se cache derrière les LHight ? ",
          text: `
            <p>Hello moi c'est Stan, bienvenue sur mon site !
                Je suis né à LH il y a une trentaine d’années.
                Mon BAC en poche, j’ai eu la chance de voyager au Canada et en Chine pour y étudier l’informatique.
                J ’y ai découvert de nouvelles cultures, de nouvelles façons de penser. J’ai vécu dans des conditions climatiques assez uniques et j’ai réalisé une chose ; la ville de béton dont je venais et que j’avais toujours voulu fuir me manquait. Il m’aura fallu être à des milliers de kilomètres du Havre pour prendre conscience de son charme et de ses trésors trop souvent dénigrés.
            </p>
            <p>"Après avoir fait le tour du monde, tout ce qu’on veut c’est être à la maison." <a href="https://www.youtube.com/channel/UCEnFzIYw3BrndPCddyQ6c5A" target="_blank"><i>Orelsan</i></a></p>
            <p>De retour dans ma ville natale, et après y avoir passé quelques mois j’ai dû partir à Paris en 2015. Je suis maintenant développeur depuis quelques années.
              Je suis passionné de nouvelles technologies, d’art et plus encore par tout ce qui relie ces deux domaines.
              C’est par nostalgie du Havre, ma ville natale et mes centres d’intérêts que l’idée des lampes LHight m’est venue.
              J’ai pris beaucoup de plaisir à développer ce site et à me former sur certaines technologies que je n'utilise pas forcément dans mon quotidien de développeur.
            </p>
          `,
          isActive: false,
        },
        {
          title: "Comment l’idée des lampes est-elle née ?",
          text: `
            <p>Le signe LH formé par les mains m’a toujours amusé et un jour, l’idée d’en faire une lampe m’a traversée l’esprit.
                J’ai d’abord pensé la réaliser en terre cuite ou en silicone mais des réflexions sur les composants électriques et mes connaissances limitées en sculpture m’ont orientées vers d'autres horizons.
                Plusieurs mois plus tard, je découvre les lettres LH de <a href="https://letter-in.com/" target="_blank">Letter-In</a> imprimées en 3D et tombe sous le charme. Quelques temps après, un ami me signale que des imprimantes 3D sont en promo. Je saute sur l’occasion !
                Je passe des nuits blanches à me familiariser avec l’imprimante, à en comprendre son fonctionnement et je me lance dans la phase la plus complexe du projet : la modélisation des mains... Après moult essais, le premier prototype voit le jour. N’étant pas 100% satisfait du résultat, je persévère, et continue d’améliorer ma lampe. J’offre des exemplaires à des proches qui, très enjoués, m’encouragent à developper le projet afin que vous puissiez, vous aussi les avoir chez vous.
            </p>
          `,
          isActive: false,
        },
        {
          title: "À qui appartiennent ces mains ?",
          text: `
            <p>Elles ont été modélisées à l’aide d’un logiciel d’animation 3D et n’ont donc pas vraiment de propriétaire ;).</p>
          `,
          isActive: false,
        },
        {
          title: "Comment fonctionne l’impression 3D ?",
          text: `
            <p>Il existe autant d’imprimantes que de types d’impressions. La mienne fonctionne avec un filament. Le filament est entrainé à l’aide d’un moteur dans une buse puis chauffé ce qui le fait fondre. Ce filament fondu est ensuite, encore à l’aide de moteurs, déposé sur une plaque. Au fur et à mesure de l’impression, la buse continue de déposer du fil couche par couche ce qui crée les objets. </p>
          `,
          isActive: false,
        },
        {
          title: "Comment LHight a t-elle été réalisée ? ",
          text: `
            <p>A l’exception de la douille et du câble d’alimentation (tous deux respectant les normes CE), LHight a entièrement été imprimée en PLA ou PLA+ selon les modèles.</p>
            <p>LHight est composée de 5 parties imprimées : un socle, deux mains, un abat jour avec son support et un tube qui relie la douille à la lampe.</p>
          `,
          isActive: false,
        },
        {
          title: "Combien de temps faut-il pour imprimer une LHight ?",
          text: `
            <p>Le temps d’impression varie en fonction des paramètres de l’imprimante qui sont régulièrement ajustés (vitesse d’impression, épaisseur des couches, personnalisation du #, etc.)
            En moyenne, chaque LHight requiert plus de 60h d’impression. À titre d’exemple, le temps d’impression de l’abat-jour varie entre 10 et 12 heures.</p>
          `,
          isActive: false,
        },
        {
          title: "De nouveaux modèles LHight à venir ?",
          text: `
            <p>Si vous avez lu l’histoire qui se cache derrière ce site, vous comprendrez que LHight est un plaisir et que je ne peux pas imprimer les lampes à la chaine. La sortie de nouveaux modèles dépendra beaucoup du temps dont je disposerai. </p>
            <p>Cependant, lorsque des nouvelles LHight seront disponibles, je vous en informerai sur les réseaux sociaux. N’hésitez donc pas à vous abonner et me suivre sur <a href="https://www.facebook.com/LHight-474534953063018" target="_blank" rel="noreferrer">Facebook</a> et <a href="https://www.instagram.com/lhight76/" target="_blank" rel="noreferrer">Instagram</a> pour rester au courant des dernières actualités.</p>
          `,
          isActive: false,
        },
        // {
        //   title: "FAQ",
        //   isActive: false,
        // },
      ],
      itemsFaq: [
        {
          title: "LHight est-elle écologique ?",
          text: `
            <p>L’impression 3D d’LHight n’est pas encore possible sans plastique. Néanmoins, je fais tout mon possible pour choisir mes bobines selon leurs caractéristiques environnementales. Je m’efforce d’améliorer ma technique d’impression pour réduire la quantité de plastique inutile pour chaque lampe au minimum. </p>
          `,
          isActive: false,
        },
        {
          title: "Puis-je personnaliser la couleur de ma LHight ?",
          text: `
            <p>Il n’est pour l’instant pas possible d’imprimer la lampe dans d’autres couleurs que celles proposées. Vous pourrez néanmoins, dans un futur proche, me contacter pour me soumettre vos souhaits. Je vous tiendrai au courant lorsque ce sera possible.</p>
          `,
          isActive: false,
        },
        {
          title:
            "Le hashtag à l’arrière du socle et le message qui figure sur l’abat jour sont-ils personnalisables ?",
          text: `
            <p>Il n’est pour l’instant pas possible de customiser les messages présents sur LHight. Vous pourrez néanmoins, dans un futur proche, me contacter pour me soumettre vos souhaits. Je vous répondrai dans les meilleurs délais.</p>
          `,
          isActive: false,
        },
        {
          title: "Puis-je changer moi-même l’orientation des mains ?",
          text: `
            <p>Il existe deux versions: 0 et 1 où l’orientation des mains diffère. Une fois acheté, il n’est pas possible de changer le sens de la main qui forme le H.</p>
          `,
          isActive: false,
        },
        {
          title: "Quelles sont les ampoules compatibles avec LHight ?",
          text: `
            <p>Toutes les ampoules avec un culot E14 sont compatibles. Cependant, je vous conseille vivement d’utiliser des LED ; les autres pourraient chauffer et risqueraient de déformer l’abat-jour. Par ailleurs, il est tout à fait possible d’installer une LED multicolore.</p>
          `,
          isActive: false,
        },
        {
          title: "Puis-je changer l’orientation de l’abat-jour ?",
          text: `
            <p>Oui sans problème, l’abat jour, contrairement aux mains, peut tourner sur lui-même quelle que soit la version.</p>
          `,
          isActive: false,
        },

        {
          title: "J’ai cassé ma LHight, que faire ?",
          text: `
            <p>Ah bah non ! C’est trop triste… Vous pouvez <a href>me contacter</a> et on essaiera de trouver une solution ensemble ;)</p>
          `,
          isActive: false,
        },
        {
          title:
            "J'ai la version 0 mais je souhaite changer le sens de la main de gauche, est-ce possbile ?",
          text: `
            <p>Il n'est pas possible de changer l'orientation des mains.</p>
          `,
          isActive: false,
        },
        {
          title:
            "J'aimerai mettre l'abat jour avec la grosse courbe à gauche, comment faire?",
          text: `
            <p>L'abat jout peut se positioner dans le sens que vous souhaitez, il suffit de tourner son support.</p>
          `,
          isActive: false,
        },
      ],
    };
  },

  mounted() {
    this.animateQuestions();
  },

  methods: {
    expand(item) {
      if (item.isActive) {
        item.isActive = false;
      } else {
        this.items.map((item) => (item.isActive = false));
        item.isActive = true;
      }
    },

    startTransition(el) {
      el.style.height = el.scrollHeight + "px";
    },

    endTransition(el) {
      el.style.height = "";
    },

    animateQuestions() {
      const questions = ".js-lh-About-inputAnimate";

      this.tl = new gsap.timeline();
      this.tl.staggerFromTo(
        questions,
        0.6,
        {
          autoAlpha: 0,
          ease: Power3.easeNone,
          y: "+=20",
        },
        {
          autoAlpha: 1,
          ease: Power3.easeNone,
          y: "0",
        },
        0.2
      );
    },
  },
};
</script>
