<template>
  <div>
    <About />
    <FAQContact :is-about="false" is-boutique is-contact />
  </div>
</template>

<script>
import About from "@/components/about/About.vue";
import FAQContact from "@/components/faqcontact/FAQContact.vue";

export default {
  name: "about",
  components: {
    About,
    FAQContact,
  },
};
</script>
